import  React,{ memo, Fragment, useState, useEffect } from 'react'
import { gql, useQuery, useLazyQuery } from '@apollo/client'
import { Dropdown } from 'react-bootstrap'
import Card from '../common/Card'
import Loader from '../../layouts/components/Loader'
import ProgressBar from '../common/ProgressBar'
import CustomToggle from '../../layouts/components/CustomToggle'
import CountUp from 'react-countup'


const GET_INSIGHTS = gql`
    query Publishers($where: PublisherCampaignsConnectionWhere, $publishersWhere2: PublisherWhere) {
        publishers(where: $publishersWhere2) {
            name
            campaignsConnection(where: $where) {
                edges {
                    addToCart
                    addToCartValue
                }
            }
        }
    }`

const GET_ARCHIVED_INSIGHTS = gql`
    query Publishers($publishersWhere: PublisherWhere, $adCampaignsWhere: AdCampaignWhere) {
        publishers(where: $publishersWhere) {
            name
            adCampaigns(where: $adCampaignsWhere) {
                addToCart
                addToCartValue
            }
        }
    }`

const icon = (
    <svg width="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M2 11.0786C2.05 13.4166 2.19 17.4156 2.21 17.8566C2.281 18.7996 2.642 19.7526 3.204 20.4246C3.986 21.3676 4.949 21.7886 6.292 21.7886C8.148 21.7986 10.194 21.7986 12.181 21.7986C14.176 21.7986 16.112 21.7986 17.747 21.7886C19.071 21.7886 20.064 21.3566 20.836 20.4246C21.398 19.7526 21.759 18.7896 21.81 17.8566C21.83 17.4856 21.93 13.1446 21.99 11.0786H2Z" fill="currentColor"></path>
        <path d="M11.2451 15.3843V16.6783C11.2451 17.0923 11.5811 17.4283 11.9951 17.4283C12.4091 17.4283 12.7451 17.0923 12.7451 16.6783V15.3843C12.7451 14.9703 12.4091 14.6343 11.9951 14.6343C11.5811 14.6343 11.2451 14.9703 11.2451 15.3843Z" fill="currentColor"></path>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.211 14.5565C10.111 14.9195 9.762 15.1515 9.384 15.1015C6.833 14.7455 4.395 13.8405 2.337 12.4815C2.126 12.3435 2 12.1075 2 11.8555V8.38949C2 6.28949 3.712 4.58149 5.817 4.58149H7.784C7.972 3.12949 9.202 2.00049 10.704 2.00049H13.286C14.787 2.00049 16.018 3.12949 16.206 4.58149H18.183C20.282 4.58149 21.99 6.28949 21.99 8.38949V11.8555C21.99 12.1075 21.863 12.3425 21.654 12.4815C19.592 13.8465 17.144 14.7555 14.576 15.1105C14.541 15.1155 14.507 15.1175 14.473 15.1175C14.134 15.1175 13.831 14.8885 13.746 14.5525C13.544 13.7565 12.821 13.1995 11.99 13.1995C11.148 13.1995 10.433 13.7445 10.211 14.5565ZM13.286 3.50049H10.704C10.031 3.50049 9.469 3.96049 9.301 4.58149H14.688C14.52 3.96049 13.958 3.50049 13.286 3.50049Z" fill="currentColor"></path>
    </svg>
)

const AddToCartWidget = memo(() =>{
    const [status, setStatus] = useState('Active')
    const [number, setNumber] = useState(0)
    const [value, setValue] = useState(0)
    const [current, setCurrent] = useState([])

    const start = 0
    const duration = 3
    const rate = 58
    const colour = 'bg-soft-info'
    const progress = 'info'

    const insights = useQuery(GET_INSIGHTS)
    const [loadArchivedInsights, archivedInsights] = useLazyQuery(GET_ARCHIVED_INSIGHTS)

    const loadData = (status) => {
        setStatus(status === 'active' ? 'Active' : 'All')
        if (status !== 'active') {
            loadArchivedInsights({
                variables: {
                    where: {
                        adCampaignsWhere: { status: "ARCHIVED" }
                    }
                },
                onCompleted: (data) => {
                    appendData(data)
                }
            })
        } else {
            setNumber(current[0])
            setValue(current[1])
        }
    }

    const appendData = (archivedData) => {
        const newData = archivedData.publishers.find((publisher) => publisher.name === 'Meta')

        const num = newData.adCampaigns.reduce((acc, curr) => {
            return acc + curr.addToCart
        }, 0)

        const val = newData.adCampaigns.reduce((acc, curr) => {
            return acc + curr.addToCartValue
        }, 0)

        setNumber(num + number)
        setValue(val + value)
    }

    useEffect(() =>{
        if (insights.data?.publishers.length > 0) {
            const data = insights.data.publishers.find((publisher) => publisher.name === 'Meta').campaignsConnection.edges

            const num = data.reduce((acc, curr) => {
                return acc + curr.addToCart
            }, 0)

            const val = data.reduce((acc, curr) => {
                return acc + curr.addToCartValue
            }, 0)

            setNumber(num)
            setValue(val)
            setCurrent([num, val])
        }
    }, [insights.data])

    return (
        <Fragment>
            { archivedInsights.loading || insights.loading ? (
                <Loader fullScreen={false} />
            ) : (
                <Card>
                    <Card.Body className="d-flex align-items-center">
                        <div className={`p-4 bg-soft-primary rounded-pill ${colour}`} style={{marginRight: '20px'}}>
                            {icon}
                        </div>

                        <div className="progress-detail w-100">
                            <div className="d-flex justify-content-between">
                                <p className="mb-3">Add to Cart</p>
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} href="#" variant="text-secondary dropdown-toggle"
                                                     size="sm" id="dropdownMenuButton1">
                                        {status}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <li><Dropdown.Item href="#" onClick={() => loadData('active')}>Active</Dropdown.Item></li>
                                        <li><Dropdown.Item href="#" onClick={() => loadData('all')}>All</Dropdown.Item></li>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <h4 className="counter">
                                <CountUp start={start} end={number} duration={duration}/>
                                <div className="mt-1">
                                    $
                                    <CountUp start={start} end={value} duration={duration}/>
                                </div>
                            </h4>
                            <div className="w-100">
                                <ProgressBar
                                    softcolors={progress}
                                    color={progress}
                                    className="shadow-none w-100"
                                    value={rate}
                                    minvalue={0}
                                    maxvalue={100}
                                    style={{height: "8px"}}
                                />
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            )}
        </Fragment>
    )
})

export default AddToCartWidget