import React, { useContext, useState, useEffect } from 'react'
import { gql, useQuery, useLazyQuery } from '@apollo/client'
import { Row, Col, Dropdown } from 'react-bootstrap'
import Chart from 'react-apexcharts'
import CustomToggle from '../../layouts/components/CustomToggle'
import Card from '../common/Card'
import { AuthContext } from '../../context/auth-context'
import Loader from '../../layouts/components/Loader'
import ProgressBar from '../common/ProgressBar'

const GET_INSIGHTS = gql`
    query Publishers($where: PublisherCampaignsConnectionWhere, $publishersWhere2: PublisherWhere) {
        publishers(where: $publishersWhere2) {
            name
            campaignsConnection(where: $where) {
                edges {
                    conversion
                    spend
                    conversionPerAds
                }
            }
        }
    }`

const GET_ARCHIVED_INSIGHTS = gql`
    query Publishers($publishersWhere: PublisherWhere, $adCampaignsWhere: AdCampaignWhere) {
        publishers(where: $publishersWhere) {
            name
            adCampaigns(where: $adCampaignsWhere) {
                conversion
                spend
            }
        }
    }`

const ConversionRateChart = ({ name }) => {
    const { user } = useContext(AuthContext)
    const [conversion, setConversion] = useState('')
    const [conversionRate, setConversionRate] = useState(0)
    const [spent, setSpent] = useState(0)
    const [currentSpent, setCurrentSpent] = useState(0)
    const [currentConversion, setCurrentConversion] = useState(0)
    const [status, setStatus] = useState('Active')
    const [chartOptions, setChartOptions] = useState({})
    const [series, setSeries] = useState([0])
    const [chartKey, setChartKey] = useState(0)

    const insights = useQuery(GET_INSIGHTS)
    const [loadArchivedInsights, archivedInsights] = useLazyQuery(GET_ARCHIVED_INSIGHTS)

    const loadData = (status) => {
        setStatus(status === 'active' ? 'Active' : 'All')
        if (status !== 'active') {
            loadArchivedInsights({
                variables: {
                    where: {
                        adCampaignsWhere: { status: "ARCHIVED" }
                    }
                },
                onCompleted: (data) => {
                    appendData(data)
                }
            })
        } else {
            setConversion(currentConversion)
            setSpent(currentSpent)
        }
    }

    const appendData = (archivedData) => {
        let spend = spent
        let conversions = parseInt(conversion)

        const newData = archivedData.publishers.find((publisher) => publisher.name === 'Meta')
        newData.adCampaigns.forEach((campaign) => {
            spend += campaign.spend
            conversions += campaign.conversion
        })

        setSpent(Math.round(spend))
        setConversion(conversions.toString())
    }

    useEffect(() => {
        if (insights.data?.publishers.length > 0) {
            const data = insights.data.publishers.find((publisher) => publisher.name === 'Meta').campaignsConnection.edges

            const conversion = data.reduce((acc, obj) => {
                return acc + obj.conversion
            }, 0)

            const spend = data.reduce((acc, obj) => {
                return acc + obj.spend
            }, 0)

            const rates = data.map((campaign) => JSON.parse(campaign.conversionPerAds))
            const flattenedObject = rates.reduce((acc, obj) => {
                return { ...acc, ...obj }
            }, {})

            setConversionRate(Math.max(...Object.values(flattenedObject)))
            setConversion(conversion.toString())
            setSpent(Math.round(spend))

            setCurrentConversion(conversion.toString())
            setCurrentSpent(Math.round(spend))
        }
    }, [insights.data, setSpent, setConversion, setConversionRate])

    useEffect(() => {
        // const spent = Math.round(data.spend)
        const formattedSpent = new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: 'AUD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(spent)

        const finalSpentValue = process.env.REACT_APP_DEMO_USER_ID.includes(user.id) ? '$75' : formattedSpent

        setChartOptions({
            labels: ['Amount Spent'],
            chart: {
                type: 'radialBar',
                offsetY: -20,
                sparkline: {
                    enabled: true
                },
            },
            colors: ['#08B1BA'],
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        background: '#3a57e81a',
                        show: true,
                        strokeWidth: '97%',
                        opacity: 1,
                        margin: 5,
                        dropShadow: {
                            enabled: false,
                            top: 0,
                            left: 0,
                            blur: 3,
                            opacity: 0.5
                        }
                    },
                    dataLabels: {
                        name: {
                            fontSize: '16px',
                            offsetY: 20,
                        },
                        value: {
                            offsetY: -25,
                            fontSize: '30px',
                            formatter: () => finalSpentValue
                        }
                    }
                }
            },
            grid: {
                padding: {
                    top: -10
                }
            },
        })

        setSeries(process.env.REACT_APP_DEMO_USER_ID.includes(user.id) ? [75] : [conversionRate])

        // Change the chart key to force re-render
        setChartKey(prevKey => prevKey + 1)
    }, [spent, user.id])

    return (
        <Col md={12} xl={4}>
            { archivedInsights.loading || insights.loading || conversion.length === 0 ? (
                <Loader fullScreen={false} />
            ) : (
                <Card data-aos="fade-up" data-aos-delay="900" className="dashboard-step-7">
                    <Card.Header className="flex-wrap d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="card-title">{name}</h4>
                        </div>
                        <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} href="#" variant="text-secondary dropdown-toggle" size="sm" id="dropdownMenuButton1">
                                {status}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <li><Dropdown.Item href="#" onClick={() => loadData('active')}>Active</Dropdown.Item></li>
                                <li><Dropdown.Item href="#" onClick={() => loadData('all')}>All</Dropdown.Item></li>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm="12" style={{minHeight: '210px'}}>
                                {chartOptions && (
                                    <Chart key={chartKey} options={chartOptions} series={series} type="radialBar" height="375" />
                                )}
                            </Col>
                        </Row>
                        <div className="d-flex mt-3 mt-sm-0">
                                <span className="avatar-50 bg-soft-info rounded">
                                    <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" height="32">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M20.9133 16.3147L20.1444 10.1201C19.676 7.90964 18.3503 7 17.0865 7H6.93171C5.65022 7 4.28034 7.84597 3.88264 10.1201L3.1049 16.3147C2.46858 20.8629 4.81062 22 7.86853 22H16.1585C19.2075 22 21.4789 20.3535 20.9133 16.3147ZM9.097 12.1486C8.60889 12.1486 8.21321 11.7413 8.21321 11.2389C8.21321 10.7366 8.60889 10.3293 9.097 10.3293C9.5851 10.3293 9.98079 10.7366 9.98079 11.2389C9.98079 11.7413 9.5851 12.1486 9.097 12.1486ZM14.002 11.2389C14.002 11.7413 14.3977 12.1486 14.8858 12.1486C15.3739 12.1486 15.7696 11.7413 15.7696 11.2389C15.7696 10.7366 15.3739 10.3293 14.8858 10.3293C14.3977 10.3293 14.002 10.7366 14.002 11.2389Z" fill="currentColor"></path>
                                        <path opacity="0.4" d="M16.9739 6.77432C16.977 6.85189 16.9621 6.92913 16.9303 7H15.4932C15.4654 6.92794 15.4506 6.85153 15.4497 6.77432C15.4497 4.85682 13.8899 3.30238 11.9657 3.30238C10.0416 3.30238 8.48184 4.85682 8.48184 6.77432C8.49502 6.84898 8.49502 6.92535 8.48184 7H7.00989C6.9967 6.92535 6.9967 6.84898 7.00989 6.77432C7.12172 4.10591 9.32499 2 12.0049 2C14.6849 2 16.8882 4.10591 17 6.77432H16.9739Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                            <div className="w-100 ms-3">
                                <div className="d-flex justify-content-between">
                                    <h6>Conversions</h6>
                                    <p>{process.env.REACT_APP_DEMO_USER_ID.includes(user.id) ? 754 : conversion}</p>
                                </div>
                                <ProgressBar
                                    softcolors="info"
                                    color="info"
                                    className="shadow-none w-100"
                                    value={process.env.REACT_APP_DEMO_USER_ID.includes(user.id) ? 75 : conversionRate}
                                    minvalue={0}
                                    maxvalue={100}
                                    style={{height: "8px"}}
                                />
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            )}
        </Col>
    )
}

export default ConversionRateChart